import React, { Component } from "react";
import AuthUser from "./authUser";
import FileSystemBrowser from "./FileSystemBrowser";
import { ProxyFrame, AuthWrapper } from "./styleComponents";
import LoadingFlicker from "./loadingFlicker";
import LoadingFileSystem from "./LoadingFileSystem";

class Master extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lines: [],
      userFolder: {},
      user: {
        email: "",
        username: "",
        id: "",
        patreon: ""
      },
      fileSystemUser: {
        username: "",
        password: ""
      },
      loading: false,
      systemLoad: true
    };

    this.authUser = this.authUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.loadFileSystemBrowser = this.loadFileSystemBrowser.bind(this);
  }

  componentDidMount() {}

  authUser(user) {
    this.setState({ user: user });
    console.log("new user2", user);
  }

  updateUser(user) {
    this.setState({ user: user });
  }

  logoutUser() {
    this.setState({});
  }

  toggleLoading(toggle) {
    this.setState({ loading: toggle });
  }

  loadFileSystemBrowser() {
    this.setState({ systemLoad: false });
  }

  render() {
    let user = this.state.user;
    let loading = this.state.loading;
    let systemLoad = this.state.systemLoad;

    return (
      <>
        <AuthWrapper>
          <ProxyFrame
            username={user.username}
            patreon={user.patreon ? user.patreon : false}
          />
          {!user.email && !user.username && (
            <>
              <AuthUser authUser={this.authUser} loading={this.toggleLoading} />
            </>
          )}
          {user.email && user.username && !loading && systemLoad && (
            <LoadingFileSystem systemLoad={this.loadFileSystemBrowser} />
          )}
          {user.email && user.username && !systemLoad && (
            <>
              <FileSystemBrowser
                user={user}
                updateUser={this.updateUser}
                logout={this.logoutUser}
                loading={this.toggleLoading}
              />
            </>
          )}
          <div
            style={{
              opacity: loading ? "1" : "0",
              pointerEvents: "none",
              position: loading ? "absolute" : "fixed",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%"
            }}
          >
            <LoadingFlicker />
          </div>
        </AuthWrapper>
      </>
    );
  }
}

export default Master;
