import socketIOClient from "socket.io-client";
let apiUrl = "/";

if (process.env.NODE_ENV === "production") {
  apiUrl = process.env.REACT_APP_APIURL;
}

const socket = socketIOClient(apiUrl);

function submitCommand(command, user) {
  //socket.on("timer", timestamp => cb(null, timestamp));
  socket.emit("typed command", {
    command: command,
    user: user
  });
}
export { submitCommand };
