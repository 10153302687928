const editorColor = "#00a4fd";
const editorBackground = "#070c25";
const proxyColor = "white";
const proxyBorderColor = "black";
const flickerColor1 = "#00a4fd";
const flickerColor2 = "#070c25";
const scrollBarColor = "#00a4fd";
const succesBorderColor = "#0070ad";
const inputColor = "#01e2ff";
const welcomeText = "Velkommen";
const welcomeDescription = "to ticketmaster 3000. Please work on your tickets.";
export {
  editorColor,
  editorBackground,
  inputColor,
  scrollBarColor,
  flickerColor1,
  flickerColor2,
  proxyColor,
  proxyBorderColor,
  succesBorderColor
};
