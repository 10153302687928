let apiUrl = "";
if (process.env.NODE_ENV === "production") {
  apiUrl = process.env.REACT_APP_APIURL;
}

console.log(
  "hest",
  process.env,
  process.env.REACT_APP_APIURL,
  process.env.REACT_APP_INFO,
  apiUrl,
  process,
  "api"
);

const isVerified = async () => {
  const response = await tryWebCall(apiUrl + "/api/user/verify", "get", {});
  return response;
};

const recoverByEmail = async (email) => {
  const response = await tryWebCall(apiUrl + "/api/user/recover", "post", {
    email: email,
  });
  return response;
};

const createUser = async (email, username, approved) => {
  const response = await tryWebCall(apiUrl + "/api/user/create", "post", {
    email: email,
    username: username,
    approved: approved,
  });
  return response;
};

const tryWebCall = async (url, method, data) => {
  try {
    let response = undefined;
    if (method === "post") {
      response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } else {
      response = await fetch(url);
    }

    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      throw new Error("404");
    }
  } catch (error) {
    return false;
  }
};

export { isVerified, createUser, recoverByEmail };
