import sponsorLogo from "../images/capgemini-logo.png";

const sponsorName = "Capgemini";
const sponsorUrl = "https://itpraten.no/kodequiz/?utm_source=kode24&utm_medium=banner&utm_campaign=kodequiz&utm_content=konkurranse";
const sponsorColor = "#0070ad";

const sponsorSuccessMessage = `Jada! Hjernen din funker! Vi i ${sponsorName} liker deg. <u>Men hvor kodeblind er du? 
Kjør test - vinn en Raspberry Pi 4</>`;

const sponsorFailMessage = `Jaja. Vi heier på deg likevel. For du er vel ikke kodeblind? <u>Men hvor kodeblind er du? 
Kjør test - vinn en Raspberry Pi 4</>`;

const sponsorFailMessageMobile = `😂Du er vel ikke kodeblind? <u>Men hvor kodeblind er du? 
Kjør test - vinn en Raspberry Pi 4</>`;

const sponsorSuccessMessageMobile = `Du får en 🌟fra oss. Men hvor kodeblind er du egentlig? 
<u>Kjør test - vinn en Raspberry Pi 4</u>`;

export {
  sponsorName,
  sponsorUrl,
  sponsorColor,
  sponsorLogo,
  sponsorSuccessMessage,
  sponsorFailMessage,
  sponsorSuccessMessageMobile,
  sponsorFailMessageMobile
};
