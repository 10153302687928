import React from "react";
import styled from "styled-components";

const TxtWrap = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TxtListing = props => {
  let content = props.filecontent;

  return (
    <TxtWrap>
      {content.map((line, key) => {
        return (
          <p key={key} className="regular">
            {line}
          </p>
        );
      })}
    </TxtWrap>
  );
};

export default TxtListing;
