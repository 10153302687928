import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { sponsorColor } from "./sponsorConfig";

import { editorBackground, editorColor } from "./theme";

const Wiggle = keyframes`
0% {
  transform: rotate(-10deg) scale(0.9);
}
50% {
  transform: rotate(10deg) scale(0.9);
}
100% {
  transform: rotate(-10deg) scale(0.9);
}
`;
// background: radial-gradient(#ffbc003d -16%, transparent);
const PolyWrapper = styled.div`
  padding: 20px;
  font-size: 20px;
  overflow: auto;
  overflow-x: hidden;
  text-transform: uppercase;
  text-align: left;
  max-height: 600px;
  position: relative;
  min-height: 450px;
  color: ${editorColor};
  background: ${editorBackground};
  .wiggle {
    animation: ${Wiggle} 2s linear infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .center {
    text-align: center;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background: transparent;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${editorColor};
    border-radius: 0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${editorColor};
  }
  pre {
    font-family: inherit;
  }
  input {
    text-align: center;
  }

  @media (min-width: 980px) {
    width: 800px;
    height: 600px;
  }
  @media (min-width: 980px) {
    padding: 40px;
  }
  .photo {
    .little {
      width: 50px;
    }
    img {
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      @media (min-width: 980px) {
        max-width: 320px;
      }
    }
  }
  p {
    margin: 0;
    text-transform: uppercase;
  }
  .error-line {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .padded-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .success-wrapper {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    .sponsor-logo {
      margin-top: 10px;
    }
    .success-sponsor-wrapper {
      padding: 10px;
      border: 1px solid #ffbc00;
      display: block;
      margin-top: 10px;
      text-decoration: none;
    }
    .sponsor-logo-wrapper {
      display: flex;
      flex-direction: column;
      background-color: #d2d2d2;
      justify-content: center;
      padding: 10px;
      align-items: center;
    }
    .sponsor-logo {
      width: 300px;
      max-width: 100%;
    }
    .success-sponsor {
      background-color: #d2d2d2;
      padding: 10px;
      line-height: 24px;
      color: black;
      margin-top: 0;
      line-height: 24px;
      text-shadow: 0 0 20px #ffffff;
      text-decoration: none;
      display: block;

      u {
        text-decoration: underline;
        color: #b130c7;
      }
    }
  }
`;

const PolyLines = styled.div`
  .regular {
    text-transform: none;
  }
`;

class Master extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lines: []
    };
    this.bottomRef = React.createRef();
  }

  addLines(newLines) {
    this.setState({
      lines: [...this.state.lines, newLines]
    });
  }

  async componentDidMount() {
    let printLines = [
      "BIOS: Toms BIOS versjon 1.0 Lille-elva-edition",
      "CPU: Beha 680x0 @ ≈7 MHz Heistad",
      "MINNE TEST: 64 Borgestadbytes OK",
      "....",
      "..........",
      ".................",
      "Auto-beregner et knippe ubrukelig data",
      "Beregner hemmeligheten med livet",
      "42",
      "Hvem bor i en ananas under havet?",
      "Spiser brødskiver, med makrell og tomat.",
      ".....",
      "Laster TomSoft DOS",
      ".....",
      "....",
      "...",
      "..",
      "."
    ];

    this.showLines(printLines.reverse());
  }

  showLines(lines) {
    if (!lines.length) {
      this.props.systemLoad();
    } else {
      setTimeout(() => {
        this.addLines({
          type: "txt",
          position: "left",
          content: [lines[lines.length - 1]]
        });
        lines.pop();
        this.showLines(lines);
      }, 75);
    }
  }

  componentDidUpdate() {}

  render() {
    let { lines } = this.state;
    return (
      <PolyWrapper>
        <PolyLines>
          {lines.map((line, index) => {
            return (
              <div
                className={`padding-wrap ${line.position ? line.position : ""}`}
                key={index}
              >
                <p key={index} className="regular">
                  {line.content}
                </p>
              </div>
            );
          })}
        </PolyLines>
      </PolyWrapper>
    );
  }
}

export default Master;
