import React from "react";
import styled from "styled-components";

const Table = styled.table`
  .binary {
    color: #e2ef13;
    text-shadow: 0 0 20px #e2ef13;
  }
  margin-top: 10px;
  margin-bottom: 10px;
  tr {
    &.right {
      text-align: right;
    }
  }
`;

const FolderListing = props => {
  let files = props.content.files || [];
  let folders = props.content.folders || [];

  return (
    <Table>
      <thead>
        <tr>
          <th />
          <th />
          <th />
          <th />
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>.</td>
          <td></td>
          <td>{"<DIR>"}</td>
          <td></td>
        </tr>
        <tr>
          <td>..</td>
          <td></td>
          <td>{"<DIR>"}</td>
          <td></td>
        </tr>
        {folders.map((folder, key) => {
          return (
            <tr key={key}>
              <td>{folder.name}</td>
              <td></td>
              <td>{"<DIR>"}</td>
              <td>
                {folder.hasAnswered && "🎅"}
                {folder.hasOnePointAnswered && "🍄"}
              </td>
            </tr>
          );
        })}
      </tbody>
      <tbody>
        {files.map((file, key) => {
          return (
            <tr key={key}>
              <td>{file.name}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          );
        })}
        <tr className="right">
          <td colSpan="4">{folders.length} mapp(er)</td>
        </tr>
        <tr className="right">
          <td colSpan="4">{files.length} fil(er)</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default FolderListing;
