let apiUrl = "";

if (process.env.NODE_ENV === "production") {
  apiUrl = process.env.REACT_APP_APIURL;
}

const getFolders = async email => {
  const response = await tryWebCall(apiUrl + "/api/files", "get", {});
  return response;
};

const getHelp = async email => {
  const response = await tryWebCall(apiUrl + "/api/help", "get");
  return response;
};

const authFolder = async (pathId, answer) => {
  const response = await tryWebCall(
    apiUrl + "/api/files/answer/" + pathId + "/" + answer,
    "get"
  );
  return response;
};

const updatePatreon = async (icon, id) => {
  const response = await tryWebCall(apiUrl + "/api/user/patreon", "post", {
    icon: icon,
    id: id
  });
  return response;
};

const createTeam = async teamName => {
  const response = await tryWebCall(apiUrl + "/api/teams/create", "post", {
    teamName: teamName
  });
  return response;
};

const joinTeam = async teamName => {
  const response = await tryWebCall(apiUrl + "/api/teams/join", "post", {
    teamName: teamName
  });
  return response;
};

const leaveTeam = async () => {
  const response = await tryWebCall(apiUrl + "/api/teams/leave");
  return response;
};

const getTeamRank = async teamId => {
  const response = await tryWebCall(apiUrl + "/api/teams/rank/" + teamId);
  return response;
};

const getUserRank = async () => {
  const response = await tryWebCall(apiUrl + "/api/user/rank");
  return response;
};

const tryWebCall = async (url, method, data) => {
  try {
    let response = undefined;
    if (method === "post") {
      response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
    } else {
      response = await fetch(url);
    }

    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      throw new Error("404");
    }
  } catch (error) {
    return false;
  }
};

export {
  getFolders,
  getHelp,
  authFolder,
  createTeam,
  joinTeam,
  leaveTeam,
  getTeamRank,
  getUserRank,
  updatePatreon
};
