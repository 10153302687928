import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { isVerified, createUser, recoverByEmail } from "../api/authAPI";
import { sponsorLogo, sponsorColor } from "./sponsorConfig";
import { ButtonWrapper } from "./styleComponents";

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const shrink = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
`;

const reverseShrink = keyframes`
  0% {
    transform: scale(0.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.1);
  }
`;

const Loading = styled.div`
  display: inline-block;
  animation: ${rotation} 1.5s linear infinite;
  .ball-1 {
    animation: ${shrink} 1.5s linear infinite;
    background-color: white;
    width: 10px;
    height: 10px;
    display: inline-block;
  }
  .ball-2 {
    animation: ${reverseShrink} 1.5s linear infinite;
    background-color: white;
    width: 10px;
    height: 10px;
    display: inline-block;
  }
`;

const SponsorLogo = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
  }
  .logo {
    width: auto;
    max-width: 100%;
  }
`;

const LoginWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: #d2d2d2;
  align-items: center;
  @media (min-width: 980px) {
    width: 800px;
    height: 600px;
  }
  h3 {
    color: ${sponsorColor};
    text-shadow: none;
    font-size: 1.2em;
    margin-bottom: 0;
  }
  h4 {
    color: black;
    text-shadow: none;
  }
  .error {
    color: ${sponsorColor};
    text-shadow: none;
  }
`;

class AuthUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        email: "",
        username: "",
        approved: false
      },
      loading: false,
      isAlreadyLoggedIn: false,
      userstate: "loginMenu",
      verified: false,
      error: false,
      errorMessage: "",
      user: {
        email: "",
        username: "",
        points: ""
      },
      typeHandlers: {}
    };
  }

  clearUser(user) {
    this.setState({
      inputs: {
        email: "",
        username: "",
        approved: false
      },
      verified: false,
      error: false,
      errorMessage: "",
      userstate: "loginMenu",
      user: {
        email: "",
        username: "",
        points: ""
      },
      typeHandlers: {}
    });
  }

  gotoMenu(state) {
    if (state === "newLogin") this.clearUser();
    this.setState({
      userstate: state
    });
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.isUserLoggedIn();
  }

  async isUserLoggedIn() {
    const user = await isVerified();
    this.setState({ loading: false });
    if (user) {
      this.setState({ user: user, isAlreadyLoggedIn: true });
    }
  }

  async updateInputField(field, value) {
    let inputsCopy = Object.assign({}, this.state);
    inputsCopy.inputs[field] = value;
    this.setState(inputsCopy);
  }

  async typeHandler(typeName, event, callback) {
    let email = event.target.value;
    this.updateInputField(typeName, email);
  }

  async radioHandler(typeName, value) {
    this.updateInputField(typeName, value);
  }

  async registerUser(event) {
    event.preventDefault();
    event.stopPropagation();

    let email = this.state.inputs.email;
    let username = this.state.inputs.username;
    let approved = this.state.inputs.approved;
    const createdResponse = await createUser(email, username, approved);
    if (createdResponse) {
      this.authUser(createdResponse);
    } else {
      this.setState({
        verified: false,
        error: true,
        errorMessage:
          "Kunne ikke opprette brukeren, eller brukeren eksisterer fra før av."
      });
    }
  }

  async recoverUserByEmail(event) {
    event.preventDefault();
    event.stopPropagation();
    let email = this.state.inputs.email;
    const recoveredUser = await recoverByEmail(email);

    if (recoveredUser) {
      this.authUser(recoveredUser);
    } else {
      this.setState({
        verified: false,
        error: true,
        errorMessage: "Fant ikke brukeren."
      });
    }
  }

  authUser(user) {
    this.props.loading(true);
    setTimeout(() => {
      this.props.authUser(user);
      this.props.loading(false);
    }, 1000);
  }

  loginWithUser(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.authUser(this.state.user);
  }

  render() {
    let { inputs, userstate, user, error, errorMessage, loading } = this.state;

    return (
      <LoginWrap>
        <SponsorLogo>
          <img src={sponsorLogo} alt="Cap Gemini logo" className="logo" />
        </SponsorLogo>
        <h2>Proxy</h2>

        {userstate === "loginMenu" && (
          <ButtonWrapper>
            {!loading && user.username && user.email && (
              <>
                <h3>Velkommen tilbake {user.username}!</h3>
                <h4>Trykk på startknappen for å ja, starte altså.</h4>
              </>
            )}
            {loading && (
              <button className="unrendered-button">
                <span>
                  <Loading>
                    <span className="ball-1"></span>
                    <span className="ball-2"></span>
                  </Loading>
                </span>
              </button>
            )}
            {!loading && user.username && user.email && (
              <button className="start" onClick={() => this.loginWithUser()}>
                <span>Start</span>
              </button>
            )}
            {!loading && !user.username && (
              <button onClick={() => this.gotoMenu("loginWithUser")}>
                <span>Logg inn</span>
              </button>
            )}
            <button onClick={() => this.gotoMenu("newLogin")}>
              <span>Ny bruker</span>
            </button>
          </ButtonWrapper>
        )}
        {userstate === "loginWithUser" && (
          <>
            <form onSubmit={event => this.recoverUserByEmail(event)}>
              <div>
                <input
                  name="e-mail"
                  type="email"
                  value={this.state.inputs.email}
                  onChange={event =>
                    this.typeHandler("email", event, "checkEmail")
                  }
                  placeholder="E-postadresse"
                />
                {error && <p className="error">{errorMessage}</p>}
              </div>
              <ButtonWrapper>
                <button>
                  <span>
                    {user.username && <>Logg inn som {user.username}</>}
                    {!user.username && <>Logg inn</>}
                  </span>
                </button>
                <button onClick={() => this.gotoMenu("loginMenu")}>
                  <span>tilbake</span>
                </button>
              </ButtonWrapper>
            </form>
          </>
        )}
        {userstate === "newLogin" && (
          <>
            <form onSubmit={event => this.registerUser(event)}>
              <div className="form-row">
                <label className="label-wrapper" htmlFor="e-mail">
                  E-postadresse
                </label>
                <input
                  id="e-mail"
                  name="e-mail"
                  type="email"
                  value={this.state.inputs.email.name}
                  placeholder="E-postadresse"
                  onChange={event => this.typeHandler("email", event)}
                />
              </div>
              <div className="form-row">
                <label className="label-wrapper" htmlFor="username">
                  Kallenavn
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  value={this.state.inputs.username.name}
                  placeholder="Brukernavn"
                  onChange={event => this.typeHandler("username", event)}
                />
              </div>
              <div className="form-row">
                <div className="label-wrapper">Vi lurer:</div>
                <div className="input-wrapper">
                  <p>
                    Er det greit om kode24 sammen med vår sponsor sender deg én e-post når konkurransen er ferdig?
                  </p>
                  <div className="input-inline-wrapper">
                    <label className="inline-label">
                      <input
                        type="radio"
                        className="checkbox"
                        name="approve-e-mail"
                        value={true}
                        id="approve-e-mail-radios"
                        checked={inputs.approved}
                        onChange={event => this.radioHandler("approved", true)}
                      />
                      <span>Så klabern!</span>
                    </label>
                    <label className="inline-label">
                      <input
                        type="radio"
                        className="checkbox"
                        name="approve-e-mail"
                        value={false}
                        id="no-e-mail-radios"
                        checked={!inputs.approved}
                        onChange={event => this.radioHandler("approved", false)}
                      />
                      <span>Nei ass.</span>
                    </label>
                  </div>
                </div>
              </div>
              {error && <p className="error">{errorMessage}</p>}
              <p></p>
              <ButtonWrapper>
                <button
                  disabled={
                    inputs.email.status === 1 ||
                    inputs.username.status === 1 ||
                    inputs.email.status === 0 ||
                    inputs.username.status === 0
                  }
                >
                  <span>Opprett bruker</span>
                </button>
                <button onClick={() => this.gotoMenu("loginMenu")}>
                  <span>tilbake</span>
                </button>
              </ButtonWrapper>
            </form>
          </>
        )}
      </LoginWrap>
    );
  }
}

export default AuthUser;
