import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { inputColor } from "./theme";
const blink = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
`;

const PolyInputContainer = styled.span`
  background-color: transparent;
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  width: 100%;
  position: relative;

  .blinker {
    display: inline-block;
    width: 7px;
    height: 3px;
    background-color: #0fe00f;
    margin-left: 4px;
    animation: ${blink} 0.4s ease-in-out infinite;
  }
  .visual-typing {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    align-items: flex-end;
    color: transparent;
  }
  .input-view {
    flex: 1 1 100%;
    position: relative;
    display: flex;
  }
  .mobile-input,
  #term-input,
  #term-input:focus,
  #term-input:visited,
  #term-input:active {
    flex: 1 1 100%;
    background-color: transparent;
    color: ${inputColor};
    text-shadow: 0 0 20px ${inputColor};
    font-family: "VT323", monospace;
    font-size: 20px;
    border: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
    outline: none;
    text-align: left;
    &:focus {
      &::placeholder {
        color: transparent;
        text-shadow: 0 0 20px transparent;
      }
    }
  }
  .input-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 1s ease-in-out;
      background-color: #ff2a00;
      opacity: 0;
      z-index: 10;
    }
    &.show-info {
      pointer-events: auto;
      cursor: pointer;
      &:before {
        opacity: 1;
        transition: all 1s ease-in-out;
      }
      &:after {
        opacity: 1;
        transition: all 1s ease-in-out;
      }
    }
  }

  .path-view {
    white-space: nowrap;
  }
`;

class Input extends Component {
  state = {
    characters: "",
    previousInputs: [],
    hasFocus: false,
    historyPos: false
  };

  inputRef = React.createRef();

  constructor(props) {
    super(props);
    this.focusInput = this.focusInput.bind(this);
    this.blurInput = this.blurInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  focusInput() {
    if (this.inputRef) {
      this.inputRef.current.focus();
      this.setState({
        hasFocus: true
      });
    }
  }

  blurInput() {
    this.inputRef.current.blur();
    this.setState({
      hasFocus: false
    });
  }

  // Sends command typed by user to parsing
  // dumps command in array for later use
  // perhaps implement up on keyboard for repeat?
  sendToParse() {
    let characters = this.state.characters;
    let previousInputs = this.state.previousInputs;
    previousInputs.push(characters);
    this.setState({
      characters: "",
      previousInputs: previousInputs
    });
    this.props.sendToParse(characters);
  }

  // when input field changes update value in state
  updateInput(value) {
    this.setState({
      characters: value
    });
  }

  handleKeyDown = event => {
    const keyCode = event.keyCode;
    if (keyCode === 13) {
      this.sendToParse();
    }
  };

  componentWillMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("focus", this.blurInput);
    window.removeEventListener("blur", this.blurInput);
  }

  componentDidMount() {
    this.focusInput();
    window.addEventListener("focus", this.focusInput);
    window.addEventListener("blur", this.blurInput);
  }

  render() {
    let path = this.props.currentPath.displayPath;
    return (
      <PolyInputContainer onClick={() => this.focusInput()}>
        <div className="path-view">{path}&nbsp;</div>
        <div className="input-view">
          <input
            id="term-input"
            type="text"
            name="mobile-input"
            value={this.state.characters}
            className="mobile-input"
            ref={this.inputRef}
            onChange={event => this.updateInput(event.target.value)}
            onBlur={event => this.blurInput()}
            autoComplete="off"
            autoFocus={true}
            placeholder="Trykk for å skrive.."
          />
        </div>
      </PolyInputContainer>
    );
  }
}

export default Input;
