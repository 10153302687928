import React, { Component } from "react";
import SystemHandler from "../components/SystemHandler";
import { injectGlobal } from "styled-components";

injectGlobal`
  @import url('https://fonts.googleapis.com/css?family=VT323');

  #root::-webkit-scrollbar {
    width: 10px;
    background: #ff00ff;
  }


  #root {
    margin: 0;
    padding: 0;
    font-family: 'VT323', monospace;
    height: 100%;
    width: 100%;
    color: #ffbc00;
    text-shadow: 0 0 20px #ffbc00;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  * {
    box-sizing: border-box;
  }

`;

class App extends Component {
  render() {
    return <SystemHandler />;
  }
}

export default App;
