import React from "react";
import styled, { keyframes } from "styled-components";

import { flickerColor1, flickerColor2 } from "./theme";

const flicker = keyframes`
  10%  {
    opacity: 1;
  }
  50% {
    transform: translateY(140px);
  }
  

  100% {
    opacity: 0.8;
    transform: translateY(-140px);
  }
`;

const LoadingFlickerStyle = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% + 40px);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  transform: translateY(0);
  div {
    width: 100%;
    height: 100%;
  }
  .p {
    background: ${flickerColor1};
  }
  .y {
    background: ${flickerColor1};
  }
  .g {
    background: ${flickerColor2};
  }
  .b {
    background: ${flickerColor2};
  }
  animation: ${flicker} 0.5s ${props => props.delay}s linear infinite;
`;

const LoadingBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
`;

const LoadingFlicker = props => (
  <>
    <LoadingBackground />
    <LoadingFlickerStyle delay="0" className={props}>
      <div className="p" />
      <div className="y" />
      <div className="g" />
      <div className="b" />
      <div className="p" />
      <div className="y" />
      <div className="g" />
      <div className="b" />
      <div className="p" />
      <div className="y" />
      <div className="g" />
      <div className="b" />
    </LoadingFlickerStyle>
    <LoadingFlickerStyle delay="0.25" className={props}>
      <div className="g" />
      <div className="y" />
      <div className="b" />
      <div className="y" />
      <div className="g" />
      <div className="b" />
      <div className="y" />
      <div className="p" />
      <div className="b" />
      <div className="p" />
      <div className="y" />
      <div className="g" />
    </LoadingFlickerStyle>
    <LoadingFlickerStyle delay="0.125" className={props}>
      <div className="p" />
      <div className="g" />
      <div className="y" />
      <div className="p" />
      <div className="y" />
      <div className="p" />
      <div className="g" />
      <div className="b" />
      <div className="y" />
      <div className="b" />
      <div className="g" />
      <div className="p" />
    </LoadingFlickerStyle>
    <LoadingFlickerStyle delay="0.425">
      <div className="g" />
      <div className="p" />
      <div className="b" />
      <div className="y" />
      <div className="b" />
      <div className="g" />
      <div className="y" />
      <div className="p" />
      <div className="b" />
      <div className="g" />
      <div className="y" />
      <div className="b" />
    </LoadingFlickerStyle>
  </>
);

export default LoadingFlicker;
