import styled, { keyframes } from "styled-components";
import { sponsorName, sponsorColor } from "./sponsorConfig";
import { proxyColor, proxyBorderColor } from "./theme";
const AuthWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  text-shadow: none;
  font-size: 1.4rem;
  color: black;
  h1 {
    color: white;
    text-transform: lowercase;
    font-size: 100px;
    text-shadow: 4px 4px 0px black;
    margin: 0;
    font-family: "VT323", monospace;
  }
  h2 {
    color: black;
    text-shadow: none;
    font-family: "VT323", monospace;
  }
  p {
    font-family: "VT323", monospace;
  }
  form {
    max-width: 600px;
  }
  .input-wrapper {
    display: block;
    padding: 10px 10px;
    p {
      text-align: left;
      font-size: 1.2rem;
      margin: 0;
      margin-bottom: 10px;
    }
  }
  .input-inline-wrapper {
    display: flex;
    align-items: center;
  }
  .inline-label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    span {
      margin-left: 10px;
    }
  }
  .form-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    color: black;
    .label-wrapper {
      color: black;
      text-shadow: none;
      width: 150px;
      flex: 0 0 150px;
      text-align: left;
      padding: 10px 10px;
      cursor: pointer;
    }
  }

  .checkbox {
    appearance: none;
    width: 25px;
    height: 25px;
    padding: 0;
    background: transparent;
    border: 4px solid ${sponsorColor};
    outline: none;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    &:hover {
      transform: scale(1.06);
    }
  }
  .checkbox:checked {
    background: ${sponsorColor};
    border: 0;
    + span {
      text-decoration: underline;
    }
  }
  input {
    width: 100%;
    display: block;
    color: white;
    background-color: ${sponsorColor};
    font-size: 20px;
    padding: 10px 10px;
    border: 0;
    text-align: left;
    text-transform: uppercase;
    font-family: "VT323", monospace;
    outline: none;
    &::placeholder {
      color: white;
    }
    &:focus,
    &:visited,
    &:active {
    }
  }
  box-shadow: 10px 16px 0px black;
  text-align: center;
  text-transform: uppercase;
  background: ${sponsorColor};
  padding: 14px;
  position: relative;

  @media (min-width: 980px) {
    width: auto;
    flex-direction: row;
    box-shadow: 20px 26px 0px black;
  }
`;

const ProxyFrame = styled.div`
  &:before {
    content: "";
    pointer-events: none;
    position: absolute;
    left: 10px;
    top: 10px;
    width: calc(100% - 28px);
    height: calc(100% - 28px);
    border: 4px solid ${proxyBorderColor};
    z-index: 10;
  }
  &:after {
    content: "${sponsorName} proxy [${props =>
  props.username ? props.username : ""}${props =>
  props.patreon ? props.patreon : ""}]";
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 0;
    text-transform: uppercase;
    color: ${proxyColor};
    text-shadow: none;
    background-color: ${sponsorColor};
    padding: 4px;
    width: auto;
    height: auto;
    transform: translateX(-50%);
    z-index: 10;
  }
`;

const jumpButton = keyframes`
  0% {
    transform: translateX(-0px) translateY(-0px);
  }
  6% {
    transform: translateX(-0px) translateY(-0px);
  }
  10% {
    transform: translateX(-4px) translateY(-4px);
  }
  16% {
    transform: translateX(-0px) translateY(-0px);
  }
  100% {
    transform: translateX(-0px) translateY(-0px);
  }
`;

const ButtonWrapper = styled.div`
  display: inline-block;

  @media (min-width: 980px) {
    flex-direction: row;
    align-items: space-between;
  }
  button {
    background: transparent;
    text-transform: uppercase;
    color: white;
    font-family: "VT323", monospace;
    font-size: 2.5rem;
    box-shadow: 4px 6px 0px black;
    border: 0;
    width: auto;
    outline: none;
    cursor: pointer;
    padding: 0.4rem 1rem;
    margin-bottom: 20px;
    position: relative;
    margin: 10px;
    background: ${sponsorColor};
    color: white;
    animation: ${jumpButton} 2s ${props => (props.delay ? props.delay : "0s")}
      infinite linear;
    transition: all 0.1s ease-in-out;
    &:hover {
      box-shadow: 6px 8px 0px black;
    }
  }
`;

export { ProxyFrame, AuthWrapper, ButtonWrapper };
