import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import Input from "./Input";
import {
  getFolders,
  authFolder,
  createTeam,
  joinTeam,
  leaveTeam,
  getTeamRank,
  getUserRank,
  updatePatreon
} from "../api/FileSystem";
import FolderListing from "./folder";
import TxtListing from "./txt";
import { submitCommand } from "./socketConnection";
import TopLogo from "../images/topplogo.png";

import {
  sponsorName,
  sponsorUrl,
  sponsorColor,
  sponsorLogo,
  sponsorSuccessMessage
} from "./sponsorConfig";

import {
  editorBackground,
  editorColor,
  scrollBarColor,
  succesBorderColor
} from "./theme";

const Wiggle = keyframes`
0% {
  transform: rotate(-10deg) scale(0.9);
}
50% {
  transform: rotate(10deg) scale(0.9);
}
100% {
  transform: rotate(-10deg) scale(0.9);
}
`;
// background: radial-gradient(#ffbc003d -16%, transparent);
const PolyWrapper = styled.div`
  padding: 20px;
  font-size: 20px;
  overflow: auto;
  overflow-x: hidden;
  text-transform: uppercase;
  text-align: left;
  max-height: 600px;

  position: relative;
  color: ${editorColor};
  background-color: ${editorBackground};
  .wiggle {
    animation: ${Wiggle} 2s 2 ease-in-out;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .center {
    text-align: center;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background: transparent;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${scrollBarColor};
    border-radius: 0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${scrollBarColor};
  }
  pre {
    font-family: inherit;
  }
  input {
    text-align: center;
  }

  @media (min-width: 980px) {
    width: 800px;
    height: 600px;
  }
  @media (min-width: 980px) {
    padding: 40px;
  }
  .photo {
    .little {
      width: 50px;
    }
    img {
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: 10px;
      @media (min-width: 980px) {
        max-width: 100%;
      }
    }
  }
  p {
    margin: 0;
    text-transform: uppercase;
  }
  .error-line {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .padded-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .success-wrapper {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    .logo {
      margin-top: 10px;
    }
    .success-sponsor-wrapper {
      padding: 10px;
      border: 1px solid ${succesBorderColor};
      display: block;
      margin-top: 10px;
      text-decoration: none;
    }
    .sponsor-logo-wrapper {
      display: flex;
      flex-direction: column;
      background-color: #d2d2d2;
      justify-content: center;
      padding: 10px;
      align-items: center;
    }
    .sponsor-icon {
      width: 40px;
      max-width: 100%;
    }
    .sponsor-logo {
      width: 300px;
      max-width: 100%;
    }
    .success-sponsor {
      background-color: #d2d2d2;
      padding: 10px;
      line-height: 24px;
      color: black;
      margin-top: 0;
      line-height: 24px;
      text-shadow: 0 0 20px #ffffff;
      text-decoration: none;
      display: block;

      u {
        text-decoration: underline;
        color: ${succesBorderColor};
      }
    }
  }
`;

const PolyLines = styled.div`
  .regular {
    text-transform: none;
    word-break: break-word;
  }
`;

const PolyInputWrapper = styled.div``;

class Master extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lines: [],
      userFolder: {},
      user: {},
      folders: [],
      path: [],
      currentPath: {},
      currentFolder: {}
    };

    this.bottomRef = React.createRef();
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  addLines(newLines) {
    // add path for history
    newLines.path = this._getPath().displayPath;

    if (newLines.type === "command") {
      submitCommand(newLines.path + newLines.content, this.props.user);
    }

    this.setState({
      lines: [...this.state.lines, newLines]
    });
  }

  clearLines() {
    this.setState({
      lines: []
    });
  }

  async _getHelp() {
    this.addLines({
      type: "txt",
      content: [
        `KOMMANDOENE SOM STØTTES:`,
        `DIR - Vis innhold i katalogen man befinner seg i`,
        `CD.. - Gå opp en mappe`,
        `CD %MAPPENAVN% - Gå inn i en mappe`,
        `PRINT %FILNAVN% - Skriv ut en fil til skjermen`,
        `STATS - Skriv ut statistikk for brukeren din`,
        `AUTH %PASSORD% - Autentiser serveren med dagens kode.`,
        `OBS, AUTH må kjøres i mappen hvor oppgaven gis.`
      ]
    });
  }

  addErrorLine(errorMessage) {
    this.addLines({
      type: "error",
      content: errorMessage
    });
  }

  async componentDidMount() {
    await this._updateFolders();

    this.addLines({
      type: "photo",
      position: "center",
      content: [`${TopLogo}`]
    });
    this.addLines({
      type: "txt",
      content: [
        `** Bruker: ${this.props.user.username}${
          this.props.user.patreon ? this.props.user.patreon : ""
        }`,
        `** Kjellerstua ticketmaster 2000 server`,
        `** Du har ${this.props.user.aggregatedAnswerCount} poeng.`,
        ` `,
        `Skriv HELP for hjælp.`,
        `************************ `,
        ` `
      ]
    });
    this.addLines({
      type: "txt",
      position: "left",
      content: [
        `Powered by Apache TomSoft [Version 3.0]`,
        `(c) 2020. Ticketmaster 2000 written in Foreclojure`
      ]
    });
  }

  async _updateFolders() {
    let folders = await getFolders();
    this.setState({ folders: folders });
    this._setCurrentFolder();
  }

  _setCurrentFolder(folder) {
    this._getPath();
    this._getFolder();
  }

  _getContentsOfFile(fileName) {
    fileName = fileName.toLowerCase();
    let folder = this._getFolder();
    let file = folder.files.find(
      file => file.name.toLowerCase() === fileName.toLowerCase()
    );
    if (file) {
      if (file.photo) {
        new Image().src = file.content[0];
      }
      this.addLines(file);
    } else {
      this.addErrorLine("Fant ikke fila");
    }
  }

  async _getStats() {
    let userStats = await getUserRank();
    this.addLines({
      type: "txt",
      content: [
        `Statistikk for bruker ${this.props.user.username}:`.toUpperCase(),
        `- Poeng: ${userStats.aggregatedAnswerCount}`.toUpperCase(),
        `- Rangering: ${userStats.rank}`.toUpperCase()
      ]
    });
  }

  _getPath() {
    if (this.state.path.length < 1) {
      let rootPath = {
        fullPath: "/",
        displayPath: "c:\\>",
        fullPathWithUsername: `${
          this.props.user.team ? `[${this.props.user.team.nameStyled}]` : ``
        }${this.props.user.username}:/$`,
        fullPathWithUsernameAndSolvedIcon: `${
          this.props.user.team ? `[${this.props.user.team.nameStyled}]` : ``
        }${this.props.user.username}:/[🎅]$`,
        parent: false,
        currentFolder: "/"
      };
      this.setState({ currentPath: rootPath });
      return rootPath;
    } else {
      let newPath = {
        fullPath: `/${this.state.path.join("/")}`,
        displayPath: `c:\\${this.state.path.join("/")}>`,
        fullPathWithUsername: `${
          this.props.user.team ? `[${this.props.user.team.nameStyled}]` : ``
        }${this.props.user.username}:/${this.state.path.join("/")}$`,
        fullPathWithUsernameAndSolvedIcon: `${
          this.props.user.team ? `[${this.props.user.team.nameStyled}]` : ``
        }${this.props.user.username}:/${this.state.path.join("/")}[🎅]$`,
        parent: `/${this.state.path.slice(1, -1).join("/")}`,
        currentFolder: this.state.path[this.state.path.length - 1]
      };
      this.setState({ currentPath: newPath });
      return newPath;
    }
  }

  _parsePathRequest(path) {
    if (path === "..") {
      if (this.state.path.length > 0) {
        let newPath = [...this.state.path];
        newPath.pop();
        this.setState({ path: newPath });
        this._getPath();
      } else {
        this.addErrorLine("Kanke gå uti der");
      }
      // entering new path
    } else {
      let folder = this._getFolder(this._getPath().fullPath, path);

      if (folder) {
        this.setState({ path: [...this.state.path, path] });
        this._getPath();
      } else {
        this.addErrorLine("fant ikke mappa");
      }
    }
  }
  _getFolder(path, currentFolder) {
    path = path ? path + "/" + currentFolder : this._getPath().fullPath;

    // returns current folder if path is not supplied
    if (path === "/") {
      let root = {
        name: "/",
        parent: false,
        files: [],
        hasAnswered: false,
        hasOnePointAnswered: false
      };
      this.setState({ currentFolder: root });
      return root;
    } else {
      currentFolder = path.split("/").pop();
      let parentPath = path.replace("/" + currentFolder, "");
      if (!parentPath) parentPath = "/";

      let foundFolder = this.state.folders.find(folder => {
        return (
          folder.parent === parentPath &&
          folder.name.toLowerCase() === currentFolder.toLowerCase()
        );
      });

      if (foundFolder) {
        if (this.props.user.answersInFolders.indexOf(foundFolder._id) > -1)
          foundFolder.hasAnswered = true;
        else foundFolder.hasAnswered = false;
        if (this.props.user.noPointAnswers.indexOf(foundFolder._id) > -1)
          foundFolder.hasOnePointAnswered = true;
        else foundFolder.hasOnePointAnswered = false;
        this.setState({ currentFolder: foundFolder });
        return foundFolder;
      }

      return false;
    }
  }

  _getListFromDirectory() {
    let path = this._getPath().fullPath;
    let foldersInPath = this.state.folders.filter(
      folder => folder.parent === path
    );
    foldersInPath = foldersInPath.map(folder => {
      if (this.props.user.answersInFolders.indexOf(folder._id) > -1)
        folder.hasAnswered = true;
      if (this.props.user.noPointAnswers.indexOf(folder._id) > -1)
        folder.hasOnePointAnswered = true;
      return folder;
    });
    let currentFolder = this._getFolder();

    this.addLines({
      type: "files",
      content: {
        folders: foldersInPath,
        files: currentFolder ? currentFolder.files : []
      }
    });
  }

  async _submitPatreon(code) {
    let user = await updatePatreon(code, this.props.user._id);

    if (user) {
      let oldUser = this.props.user;
      oldUser.patreon = user.patreon;
      this.props.updateUser(oldUser);
      this.addLines({
        type: "txt",
        content: [
          `Heisann Patreon! Takk for din støtte. Oppgraderte brukernavnet ditt litt.`
        ]
      });
    } else {
      this.addErrorLine("Sikker på at du er en Patreon?");
    }
  }

  async _submitPathCode(code) {
    let folder = this._getFolder();
    if (folder._id) {
      let answer = await authFolder(folder._id, code);
      if (!answer) {
      } else if (!answer.answerResponse.success) {
        this.addErrorLine(answer.answerResponse.content);
      } else if (answer.answerResponse.success) {
        // HACK

        this.props.updateUser(answer.user);
        this.addLines({
          type: "success",
          content:
            folder.name === "HACKER"
              ? "Grattis! Du fant brukernavnet mitt! Har du funnet det ekte navnet mitt? Mail det til tom@tomshosting.no."
              : answer.answerResponse.content
        });
      } else {
        this.addErrorLine(answer.answerResponse.content);
      }
    } else {
      this.addErrorLine("Ingen kode å sende inn her. Prøv en mappe.");
    }
  }

  scrollToBottom = () => {
    this.bottomRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start"
    });
  };

  componentDidUpdate() {
    this.scrollToBottom();

    // stupid implementation
    window.setTimeout(() => {
      this.scrollToBottom();
    }, 500);
    window.setTimeout(() => {
      this.scrollToBottom();
    }, 1000);
  }

  parseLine(line) {
    this.addLines({
      type: "command",
      content: line
    });

    let lineContent = line.toUpperCase().split(" ");
    if (lineContent.length > 3) {
      this.addErrorLine("Nå blei jeg litt forvirra");
      return;
    }

    switch (lineContent[0]) {
      case "CD..":
        this._parsePathRequest("..");
        break;

      case "CD":
        if (lineContent.length > 1) this._parsePathRequest(lineContent[1]);
        else {
          this.addErrorLine("Cd som i compact disc da eller?");
        }
        break;
      case "STATS":
        this._getStats();
        break;
      case "HELP":
        this._getHelp();
        break;
      case "CLEAR":
        this.clearLines();
        break;
      case "DIR":
        this._getListFromDirectory();
        break;
      case "AUTH":
        if (lineContent.length > 1) {
          this._submitPathCode(lineContent[1]);
        } else {
          this.addErrorLine("Mangler kodeord");
        }
        break;
      case "AUTH.EXE":
        if (lineContent.length > 1) {
          this._submitPathCode(lineContent[1]);
        } else {
          this.addErrorLine("Mangler kodeord");
        }
        break;
      case "PATREON":
        if (lineContent.length > 1) {
          this._submitPatreon(lineContent[1]);
        } else {
          this.addErrorLine("Mangler kodeord");
        }
        break;
      case "LOGOUT":
        this.setState({ user: {} });
        this.props.logout();
        break;
      case "PRINT":
        if (lineContent.length > 1) this._getContentsOfFile(lineContent[1]);
        else {
          this.addErrorLine("Må ha noe å printe også eller?");
        }
        break;
      default:
        this.addErrorLine("Detta forstod jeg ikke");
        break;
    }
  }

  render() {
    let user = this.props.user;
    let { currentPath, currentFolder, lines } = this.state;
    return (
      <PolyWrapper>
        <PolyLines>
          {lines.map((line, index) => {
            if (line.type === "ascii")
              return <pre key={index}>{line.content}</pre>;
            if (line.type === "giphy")
              return (
                <div>
                  <img height="140" alt="gif" src={line.content} />
                </div>
              );
            if (line.type === "command")
              return (
                <p key={index}>
                  {line.path}&nbsp;{line.content}
                </p>
              );
            if (line.type === "success")
              return (
                <div className="success-wrapper">
                  <p key={index} className="success">
                    {line.content}
                  </p>
                  <a
                    className="success-sponsor-wrapper"
                    href={sponsorUrl}
                    target="new_window"
                  >
                    <div className="sponsor-logo-wrapper">
                      <img
                        src={sponsorLogo}
                        alt="sponsor logo"
                        className="sponsor-logo"
                      />
                    </div>
                    <div
                      class="success-sponsor"
                      dangerouslySetInnerHTML={{
                        __html: sponsorSuccessMessage
                      }}
                    ></div>
                  </a>
                </div>
              );
            if (line.type === "error")
              return (
                <p className="error-line" key={index}>
                  ** ERROR: &nbsp;{line.content}&nbsp;**
                </p>
              );
            if (line.type === "regular")
              return (
                <div
                  className={`padding-wrap ${
                    line.position ? line.position : ""
                  }`}
                >
                  <p key={index} className="regular">
                    {line.content}
                  </p>
                </div>
              );
            if (line.type === "txt")
              return (
                <div
                  className={`${line.position ? line.position : ""}`}
                  key={index}
                >
                  <TxtListing filecontent={line.content} />
                </div>
              );
            if (line.type === "photo")
              return (
                <div
                  key={index}
                  className={`photo ${line.position ? line.position : ""} ${
                    line.additionalClasses ? line.additionalClasses : ""
                  }`}
                >
                  <img src={line.content[0]} alt="" />
                </div>
              );
            if (line.type === "files")
              return <FolderListing key={index} content={line.content} />;
            return <p key={index} />;
          })}
        </PolyLines>
        <PolyInputWrapper innerRef={this.bottomRef}>
          {!user}
          <Input
            sendToParse={this.parseLine.bind(this)}
            currentPath={currentPath}
            currentFolder={currentFolder}
          />
        </PolyInputWrapper>
      </PolyWrapper>
    );
  }
}

export default Master;
